@import '../../variables.module.scss';

.loginSection {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 0px;
  background-color: #fff;
  .logo {
    margin-bottom: 30px;
    width: 88px;
    // height: 44px;
  }

  .loginBox {
    background-color: #f5f5f6;
    width: 550px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 50px;
    border-radius: 10px;

    input:-internal-autofill-selected {
      background-color: #fff !important;
    }
    .formBoxEn {
      margin-bottom: 20px;
      position: relative;
      text-align: left;

      :global(.form-label) {
        font-weight: 600;
      }

      .formClass {
        height: 50px;
        border-radius: 8px;
        border-color: #d2d2d2;
        background-color: #fff;

        &:focus {
          border-color: #d2d2d2;
          box-shadow: none;
        }
      }
      .passwordAction {
        font-size: 22px;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #5e5c5c;
        cursor: pointer;
        line-height: 0px;
      }
    }
    .formBoxAr {
      margin-bottom: 20px;
      position: relative;
      text-align: right;

      :global(.form-label) {
        font-weight: 600;
      }

      .formClass {
        height: 50px;
        border-radius: 8px;
        border-color: #d2d2d2;
        background-color: #fff;

        &:focus {
          border-color: var(--Main2);
          box-shadow: none;
        }
      }
      .passwordAction {
        font-size: 22px;
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #5e5c5c;
        cursor: pointer;
        line-height: 0px;
      }
      .forogotPassword {
        text-decoration: none;
        color: #000;
        margin-top: 20px;
        display: flex;
        font-weight: 400;
        text-align: start;
        justify-content: left;
      }
    }

    .formBoxEn {
      .forogotPassword {
        text-decoration: none;
        color: #000;
        margin-top: 20px;
        display: block;
        font-weight: 400;
      }
    }
    .submitBtnRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .submitBtn {
        background-color: var(--Main2);
        color: #fff;
        font-weight: 600;
        border-color: transparent;
        padding: 6px 16px;
        text-decoration: none;
        text-align: center;
        border-radius: 25px;
      }
    }
    .remberme {
      margin-top: 20px;
      font-weight: 400;
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;

  &:hover input ~ .checkmark {
    background-color: #f3f1f1;
    border: 1px solid #d8d8d8;
  }

  input {
    opacity: 0;
    margin-right: 10px;
    position: absolute;

    &:checked ~ .checkmark {
      background-color: var(--Main2);
      border: 1px solid var(--Main2);
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d8d8d8;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.pointer {
  cursor: pointer !important;
  font-size: 20;
}
.subHeader {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.signIn {
  font-size: 25px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 20px;
  font-family: 'Noto Kufi Arabic', sans-serif;
}
.signUp {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Noto Kufi Arabic', sans-serif;
  padding-left: 10px;
  border: 0;
  text-decoration: none;
  color: #004fee;
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .successWrapper {
    width: 50%;
    text-align: center;
    h5 {
      margin-bottom: 1rem;
      margin-top: 6rem;
      text-align: left;
    }
    p {
      margin-bottom: 3rem;
      text-align: left;
    }
    .successFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .loginSection {
    padding: 0px;
    width: 100%;
    background-color: #fff;
    .logo {
      margin-bottom: 30px;
      width: 88px;
      // height: 44px;
    }
    .loginContainer {
      padding: 0px;
    }
    .loginBox {
      width: 100%;
      height: 100%;
      padding: 50px 30px;
      border-radius: 0px;
      .formBoxEn {
        .forogotPassword {
          text-decoration: none;
          color: #000;
          margin-top: 25px;
          display: block;
          font-weight: 400;
        }
      }

      .remberme {
        margin-top: 20px;
        font-weight: 400;
      }
      .submitBtnRow {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: end;

        .submitBtn {
          background-color: var(--Main2);
          color: #fff;
          font-weight: 600;
          border-color: transparent;
          width: auto;
          min-width: 67px;
          padding: 8px 10px;
          // width: '67px';
          // height: '48px';
          // padding: 12px 16px;
          text-decoration: none;
          text-align: center;
          border-radius: 25px;
        }
      }
      .logo {
        margin-bottom: 60px;
        width: 20px;
      }
      :global(.text-end) {
        text-align: center !important;
      }
      .remberme {
        text-align: center;
        margin-top: 25px;
      }
      .forogotPassword {
        margin-top: 40px;
        color: #004fee !important;
      }
    }
  }
  .successContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .successWrapper {
      width: 90%;
    }
  }
}
