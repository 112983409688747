.custom__circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;

  &-red {
    font-weight: bold;
    background-color: #eb5757;
  }
  &-green {
    background-color: #39eb4e;
  }
  &-gray {
    background-color: #bdbdbd;
  }
}
