:root {
  --Main: #eef5ff;
  --Main2: #004fee;
  --Color: #4d4d4d;
  --black-color: #000;
  //--BodyBackground:#FAFAFA;
  --BodyBackground: #fafafa;

  // gray
  --light-gray-color-1: #f7f7f7;
  --light-gray-color-2: #eee;
  --light-gray-color-3: #fafafa;
  --light-gray-color-4: #dee2e6;
  --light-gray-color-5: #e5e5e5;
  --light-gray-color-6: #bcbcbc;
  --light-gray-color-7: #e5e5e5;
  --light-gray-color-8: #f2f2f2ff;
  --dark-gray-color: #777;
  --dark-gray-color-2: #4d4d4d;
  --dark-gray-color-3: #333;
  --body-color: #f5f5f5;
  --tree-wrapper: #ababb1;
  --primary-color: #004feeff;
  --primary-light-color: #eef5ff;
  --primary-light-color-2: rgb(109, 156, 251);
  --light-blue-color: #aacbfe;
  --secondary-color-light: #ffb900;
  --secondary-color-dark: #ff7730;
  --secondary-color-light: #fcc7aa;
  --red-color: #ff5b5b;
  --danger-color: #ff1c0d;
  --red-color-light: #e05151;
  --white-color: #fff;
  --black-color: #000;
  --black-transparent-color: rgba(0, 0, 0, 0.3);
  --active-color: #00cecc;
  --green-color: #05b705;
  --success-color: #027a48;
  --success-light-color: #ecfdf3;
  --warning-light-color: #fff5e7;
  --warning-color: #ffd061ff;
  --addition-color: #ecfdf3;
  --danger-light-color: #fef1f1;
  --font-size-small: 14px;
  --padding-small: 10px 0px 10px 0px;
  --padding-big: 35px 0px;
}
