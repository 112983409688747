/* MarketplaceItem.css */

.customcard {
  border-radius: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px 24px 16px;
}
.customcardinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customimage {
  width: 120px;
  height: 120px;
}

.customtitle {
  margin-top: 32px;
}

.customdescription {
  font-size: 16px;
  font-weight: 400;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 32px;
}

.custombutton {
  background-color: #004fee;
  color: #fff;
  padding: 8px 0px;
  width: 100%;
  border-radius: 25px;
  outline: none;
}
.headertext {
  font-size: 18px;
  font-weight: 400;
  color: #4d4d4d;
}
