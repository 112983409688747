.cardBody {
  background: transparent !important;
}

.pageTitle {
  z-index: 99;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rowTitleLeft {
    h5 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #646464;

      .back {
        color: var(--Main2);
        text-decoration: none;
        margin-right: 20px;
      }
    }
  }

  .rowTitleRight {
    display: flex;

    :global(.btn) {
      background-color: #fff;
      border-color: #b3b3b3;
      color: #b3b3b3;
      border-radius: 50px;
      min-width: 80px;
      padding: 8px 15px;
      margin-left: 10px;
      font-weight: normal;
      font-size: 14px;
      white-space: nowrap;

      .icon {
        margin-right: 5px;
        margin-top: -1px;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.usermore {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.editRole {
  font-size: small;
}

.notUsedBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 32px;
  background-color: #5ecfd1;
  padding: 4px 8px 4px 8px;
  color: white;
  border: none;
  font-size: 13px;
  border-radius: 6px;
}
.UsedBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 32px;
  background-color: #e05151;
  padding: 4px 8px 4px 8px;
  color: white;
  border: none;
  font-size: 13px;
  border-radius: 6px;
}

.promotions {
  padding-bottom: 7px;
}
.navnewpage {
  text-decoration: none;
}

.moreOption {
  border-radius: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  padding-left: 30px;
  margin-bottom: 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  color: var(--Color);
  transition: 0.1s;

  &:hover {
    box-shadow: 1px 5px 10px #99a2ac80;
    color: var(--Main2);
  }
}
.headertabs {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: -15px;
}
.headertabslinks {
  display: flex;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: black;
  margin-right: 30px;
  position: relative;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
}
.headertabslinks:hover {
  border-bottom: 2px solid var(--Main2);
  color: var(--Main2);
}
.headertabslinks:global(.active) {
  border-bottom: 2px solid var(--Main2);
  color: var(--Main2);
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .headertabs {
    overflow-x: auto !important;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
}
