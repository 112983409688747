.label-shared {
  bottom: 18px;
  font-size: 12px;
  color: black;
}

.placeholder-container {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  height: auto;
  cursor: pointer;
  label {
    position: absolute;
    padding: 13px 25px;
    top: 2;
    cursor: text;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    color: var(--light-gray-color-6);
    cursor: pointer;
  }

  input {
    padding: 20px 25px 10px 25px;
    font-size: 13px;
    height: 50px;
    outline: none;

    border: 1px solid var(--light-gray-color-5);
    border-radius: 50px;
    cursor: pointer;
    &::placeholder {
      color: var(--light-gray-color-6);
      font-size: 16px;
    }
    &:hover {
      border: 1px solid var(--primary-color);
    }
    &:focus {
      border: 1.5px solid var(--primary-color);
      ~ label {
        @extend .label-shared;
        font-weight: 600;
      }
    }
    &:valid {
      ~ label {
        transition: none;
        @extend .label-shared;
      }
    }
  }

  .selectPlaceHolder {
    padding: 20px 25px 11px 25px;
    font-size: 13px;
    height: 50px;
    outline: none;
    transition: 0.5s ease;
    border: 1px solid var(--light-gray-color-5);
    border-radius: 50px;
    z-index: 9999;
    background-color: transparent;
    cursor: pointer;
    appearance: none;

    padding-right: 20px;
    &:hover {
      border: 1px solid var(--primary-color);
    }
    &:focus {
      border: 1.5px solid var(--primary-color);
    }
  }
  .valid {
    padding: 16px 25px 13px 25px;

    @extend .label-shared;
  }

  .fa {
    position: absolute;
    right: 0;
    bottom: 13px;
    padding-right: 18px;
  }
}
