@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../src/fonts/NotoKufiArabic-Thin.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
