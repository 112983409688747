@import 'variables.module.scss';

body {
  background-color: var(--BodyBackground) !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.5px;
}

img {
  display: inline-block !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.primary {
  background: var(--Main2) !important;
}

.SubmitBtn {
  background: var(--Main2) !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  margin-left: 5px !important;
  padding: 8px 30px 8px 30px !important;
  text-transform: capitalize !important;
  border-radius: 30px !important;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.customInput {
  border-radius: 25px;
  border: 1px #b3b3b3 solid;
  padding: 4px 12px;
  background-color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent;
    color: #b3b3b3;
    svg {
      color: #b3b3b3 !important;
    }
    :focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.exportBtn {
  color: #4d4d4d;
  display: block;
  padding: 8px 15px;
  font-size: 12px;
  cursor: pointer;
}
.calendarDropdown {
  :global(.dropdown-menu) {
    padding: 0px 0px !important;
    overflow: hidden;
  }
}

:global(.dropdown-toggle) {
  &::after {
    display: none;
    background-image: url(images/arrow-down.svg);
    border: none !important;
    width: 14px;
    height: 7px;
    vertical-align: 1px !important;
    margin-left: 10px !important;
  }
}
.pageWrapper {
  @media print {
    margin-left: 0px;
  }
  .pageTitle {
    // margin-left: 250px;
    z-index: 99;
    background: #fff;
    margin: 0;
    padding: 15px 60px;
    border-bottom: 1px solid #eeeeee;

    .back {
      color: var(--Main2);
      text-decoration: none;
      margin-right: 20px;
    }

    .backBtn {
      text-decoration: none;
      color: #808080;
      display: inline-block;
      margin-bottom: 5px;
      font-size: 14px;
      cursor: pointer;
    }

    .pageTitleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rowTitleLeft {
      h5 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0px;
        color: #808080;
        display: flex;
        align-items: center;
      }
    }

    .rowTitleRight {
      display: flex;

      .selectDropdown {
        display: inline-block;
        margin-left: 10px;

        :global(.multi-select) {
          :global(.dropdown-container) {
            display: flex;
            align-items: center;
            background-color: #f2f2f2;
            border: none;
            outline: none !important;
            color: #004fee;
            border-radius: 50px;
            height: 32px;

            :global(.dropdown-heading-value) {
              width: 100px;
              text-align: left;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: break-spaces;
              word-break: break-all;
              font-size: 14px;

              span {
                display: block;
              }
            }

            :global(.clear-selected-button) {
              display: none;
            }
          }

          :global(.dropdown-content) {
            z-index: 99;

            :global(.panel-content) {
              padding: 15px 5px;
              border-radius: 10px;
              width: 229px;

              :global(.search) {
                border: none;
                margin-bottom: 5px;

                input {
                  background: #fff;
                  border: 1px solid #dbdbdb;
                  border-radius: 50px;
                  font-size: 12px;
                  padding: 0px 15px;
                }
              }

              :global(.options) {
                :global(.select-item) {
                  padding: 4px 10x;
                  font-weight: 400;
                  color: #4d4d4d;
                  // background: #fff;

                  :global(.item-renderer) {
                    position: relative;
                    align-items: center;

                    input {
                      width: 18px;
                      height: 18px;
                      position: relative;
                      top: 1px;
                      left: 2px;
                      border: none;
                      border-radius: 14px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        :global(.css-1s2u09g-control),
        :global(.css-1insrsq-control),
        :global(.css-1pahdxg-control) {
          border: 1px solid #b3b3b3;
          padding: 0px 15px;
          border-radius: 50px;
          box-shadow: none;

          &:hover,
          &:focus {
            border: 1px solid #b3b3b3;
            box-shadow: none;
          }

          :global(.css-1okebmr-indicatorSeparator) {
            display: none;
          }

          :global(.css-tlfecz-indicatorContainer),
          :global(.css-1gtu0rj-indicatorContainer) {
            padding: 0px 0px;
          }
        }
      }

      :global(.btn a) {
        text-decoration: none;
        color: #b3b3b3;
      }

      :global(.btn) {
        background-color: #f2f2f2;
        color: #004fee;
        border-radius: 50px;
        padding: 6px 16px;
        border: none;
        margin-left: 10px;
        font-size: 14px;
        margin-right: 5px;
        white-space: nowrap;

        &.themeBtn {
          background: var(--Main2);
          //border-color: var(--Main2);
          color: #ffff;
        }

        .icon {
          margin-right: 5px;
        }
        .iconSize {
          margin-right: 5px;
          margin-top: -1px;
          // border-radius: 50%;
          // padding: 20px;
          border: 1px solid #b3b3b3;
        }

        &:focus {
          box-shadow: none;
        }

        .iconClose {
          margin-left: 10px;
          margin-top: 1px;
          cursor: pointer;
        }
      }

      :global(.btnDltModal) {
        background-color: #ee5253;
        border-color: #ee5253;
        color: #fff;
        border-radius: 10px;
        min-width: 80px;
        padding: 8px 15px;
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;

        &.themeBtn {
          background: var(--Main2);
          border-color: var(--Main2);
          color: #ffff;
        }

        .icon {
          margin-right: 5px;
        }

        &:focus {
          box-shadow: none;
        }

        .iconClose {
          margin-left: 10px;
          margin-top: 1px;
          cursor: pointer;
        }
      }

      :global(.dropdown) {
        display: inline-block;
        margin-block: auto;

        :global(.dropdown-menu) {
          padding: 10px 0px;
          border-radius: 10px;
          border: none;
          box-shadow: 0 1px 5px 2px #0000001f;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              :hover {
                background-color: #d6dbe7;
              }

              a {
                color: #4d4d4d;
                text-decoration: none;
                display: block;
                padding: 8px 15px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .pageWrapperInside {
    // background-color: var(--BodyBackground);
    padding: 60px 60px;
    @media print {
      padding: 30px 60px;
    }

    &.setWidth {
      max-width: 70rem;
      margin: 0 auto;
    }

    :global(.card) {
      background-color: transparent;
      border: none;

      :global(.card-body) {
        padding: 0px;
        margin-bottom: 0px;
        // background: #fff;
        border-radius: 15px;
      }
      .moreroles {
        // top: "15px";
        // right:  "5%" ;
        :global(.dropdown) {
          :global(.btn) {
            background-color: #fff;
            border-color: #fff;
            color: #b3b3b3;
            // border-radius: 50px;
            min-width: 80px;
            padding: 8px 15px;
            font-weight: normal;
            font-size: 14px;
            white-space: nowrap;
          }

          :global(.dropdown-menu) {
            padding: 10px 0px;
            border-radius: 10px;
            border: none;
            box-shadow: 0 1px 5px 2px #0000001f;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                :hover {
                  background-color: #d6dbe7;
                }

                a {
                  color: #4d4d4d;
                  text-decoration: none;
                  display: block;
                  padding: 8px 15px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.emptybox {
  width: 45px;
  height: 45px;
  background-color: lightgray;
  border-radius: 5px;
  margin: 10px 10px 10px 0;
  flex: auto;
}

.moretext {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  &:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
  }
}

.pageWrapperAr {
  .pageTitle {
    // margin-left: 250px;
    z-index: 99;
    background: #fff;
    margin: 0;
    padding: 15px 60px;
    border-bottom: 1px solid #eeeeee;

    .back {
      color: var(--Main2);
      text-decoration: none;
      margin-right: 20px;
    }

    .backBtn {
      text-decoration: none;
      color: #808080;
      display: inline-block;
      margin-bottom: 5px;
      font-size: 14px;
      cursor: pointer;
    }

    .pageTitleRow {
      display: flex;
      justify-content: space-between;
    }

    .rowTitleLeft {
      h5 {
        font-size: 30px;
        font-weight: 300;
        margin-bottom: 0px;
        color: #808080;
        display: flex;
        align-items: center;
      }
    }

    .rowTitleRight {
      display: flex;

      .selectDropdown {
        display: inline-block;
        margin-left: 10px;
        // padding-top: 20px;

        :global(.multi-select) {
          :global(.dropdown-container) {
            background-color: #fff;
            border-color: #b3b3b3;
            color: #b3b3b3;
            border-radius: 50px;

            :global(.dropdown-heading-value) {
              width: 100px;
              text-align: left;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: break-spaces;
              word-break: break-all;
              font-size: 14px;

              span {
                display: block;
              }
            }

            :global(.clear-selected-button) {
              display: none;
            }
          }

          :global(.dropdown-content) {
            z-index: 99;

            :global(.panel-content) {
              padding: 15px 5px;
              border-radius: 10px;
              width: 229px;

              :global(.search) {
                border: none;
                margin-bottom: 5px;

                input {
                  background: #fff;
                  border: 1px solid #dbdbdb;
                  border-radius: 50px;
                  font-size: 12px;
                  padding: 0px 15px;
                }
              }

              :global(.options) {
                :global(.select-item) {
                  padding: 4px 10x;
                  font-weight: 400;
                  color: #4d4d4d;
                  // background: #fff;

                  :global(.item-renderer) {
                    position: relative;
                    align-items: center;

                    input {
                      width: 18px;
                      height: 18px;
                      position: relative;
                      top: 1px;
                      left: 2px;
                      border: none;
                      border-radius: 14px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        :global(.css-1s2u09g-control),
        :global(.css-1insrsq-control),
        :global(.css-1pahdxg-control) {
          border: 1px solid #b3b3b3;
          padding: 0px 15px;
          border-radius: 50px;
          box-shadow: none;

          &:hover,
          &:focus {
            border: 1px solid #b3b3b3;
            box-shadow: none;
          }

          :global(.css-1okebmr-indicatorSeparator) {
            display: none;
          }

          :global(.css-tlfecz-indicatorContainer),
          :global(.css-1gtu0rj-indicatorContainer) {
            padding: 0px 0px;
          }
        }
      }

      :global(.btn a) {
        text-decoration: none;
        color: #b3b3b3;
      }

      :global(.btn) {
        background-color: #fff;
        border-color: #b3b3b3;
        color: #b3b3b3;
        border-radius: 50px;
        min-width: 80px;
        padding: 8px 15px;
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;

        &.themeBtn {
          background: var(--Main2);
          border-color: var(--Main2);
          color: #ffff;
        }

        .icon {
          margin-right: 5px;
        }
        .iconSize {
          margin-right: 5px;
          margin-top: -1px;
          // border-radius: 50%;
          // padding: 20px;
          border: 1px solid #b3b3b3;
        }

        &:focus {
          box-shadow: none;
        }

        .iconClose {
          margin-left: 10px;
          margin-top: 1px;
          cursor: pointer;
        }
      }

      :global(.btnDltModal) {
        background-color: #ee5253;
        border-color: #ee5253;
        color: #fff;
        border-radius: 10px;
        min-width: 80px;
        padding: 8px 15px;
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;

        &.themeBtn {
          background: var(--Main2);
          border-color: var(--Main2);
          color: #ffff;
        }

        .icon {
          margin-right: 5px;
        }

        &:focus {
          box-shadow: none;
        }

        .iconClose {
          margin-left: 10px;
          margin-top: 1px;
          cursor: pointer;
        }
      }

      :global(.dropdown) {
        display: inline-block;

        :global(.dropdown-menu) {
          padding: 10px 0px;
          border-radius: 10px;
          border: none;
          box-shadow: 0 1px 5px 2px #0000001f;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              :hover {
                background-color: #d6dbe7;
              }

              a {
                color: #4d4d4d;
                text-decoration: none;
                display: block;
                padding: 8px 15px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .pageWrapperInside {
    // background-color: var(--BodyBackground);
    padding: 60px 60px;

    &.setWidth {
      max-width: 70rem;
      margin: 0 auto;
    }

    :global(.card) {
      background-color: transparent;
      border: none;

      :global(.card-body) {
        padding: 0px;
        margin-bottom: 0px;
        // background: #fff;
        border-radius: 15px;
      }
      .moreroles {
        :global(.dropdown) {
          :global(.btn) {
            background-color: #fff;
            border-color: #fff;
            color: #b3b3b3;
            // border-radius: 50px;
            min-width: 80px;
            padding: 8px 15px;
            font-weight: normal;
            font-size: 14px;
            white-space: nowrap;
          }

          :global(.dropdown-menu) {
            padding: 10px 0px;
            border-radius: 10px;
            border: none;
            box-shadow: 0 1px 5px 2px #0000001f;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                :hover {
                  background-color: #d6dbe7;
                }

                a {
                  color: #4d4d4d;
                  text-decoration: none;
                  display: block;
                  padding: 8px 15px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

:global(.tooltip) {
  :global(.tooltip-inner) {
    background-color: #fff;
    color: #808080;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 12px;
    padding: 10px 10px;
  }

  :global(.tooltip-arrow) {
    display: none;
  }
}

.tooltips {
  cursor: pointer;
  position: relative;

  svg {
    color: #808080;
    width: 18px;
    height: 18px;
  }
}

.prImage {
  border-radius: 5px;
  height: 45px;
  object-fit: cover;
  width: 45px;
}

.inactiveBtn {
  background: #c41f1f;
  border-radius: 6px;
  padding: 3px;
  font-weight: 300;
  font-size: 14px;
  margin: 0 5px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 12px;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 860px) {
  .pageWrapper {
    margin-left: 0px;

    .pageTitle {
      padding: 20px 20px;
      display: block;
      .pageTitleRow {
        // display: block;
      }
      .rowTitleLeft {
        margin-bottom: 20px;

        h5 {
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 0px;
          color: #4d4d4d;
          display: flex;
          align-items: center;

          .back {
            color: var(--Main2);
            text-decoration: none;
            margin-right: 20px;
          }
        }
      }

      .rowTitleRight {
        display: block;

        padding-bottom: 10px;
        .selectDropdown {
          margin: auto !important;
        }
        :global(.btn) {
          min-width: auto;
        }
      }
    }

    .pageWrapperInside {
      padding: 20px 20px;
    }
  }
  .pageWrapperAr {
    margin-right: 0px;

    .pageTitle {
      padding: 20px 20px;
      display: block;
      .pageTitleRow {
        display: block;
      }
      .rowTitleLeft {
        margin-bottom: 20px;

        h5 {
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 0px;
          color: #4d4d4d;
          display: flex;
          align-items: center;

          .back {
            color: var(--Main2);
            text-decoration: none;
            margin-right: 20px;
          }
        }
      }

      .rowTitleRight {
        display: block;

        padding-bottom: 10px;

        :global(.btn) {
          min-width: auto;
        }
      }
    }

    .pageWrapperInside {
      padding: 20px 20px;
    }
  }
}

@media (max-width: 1130px) {
  .rowTitleRight {
    display: block;

    padding-bottom: 10px;
    // overflow-x: scroll;

    :global(.btn) {
      min-width: auto;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (max-width: 5520px) and (min-width: 768px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
}

@media (min-width: 1200px) {
}

@media print {
  .pageWrapper {
    .pageTitle {
      .rowTitleRight {
        display: none;
        // overflow:hidden;
        overflow-x: scroll;
      }

      .backBtn {
        display: none;
        cursor: pointer;
      }
    }
  }
}

.hide_dropdown_toggle::after {
  display: none !important;
}
.hide_dropdown_toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px 10px !important;
  min-width: 0px !important;
}

/* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */

@media (max-width: 767px) {
  .rowTitleRight2 {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}
