.__ActionDropDown .dropdown .btn {
    background-color: transparent;
    border-color: transparent;
    color: #b3b3b3;
    padding: 8px 15px;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
}

.__ActionDropDown .dropdown-menu {
    padding: 10px 0px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 1px 5px 2px #0000001f;
}

.__ActionDropDown .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.__ActionDropDown .dropdown-menu ul li:hover {
    background-color: #d6dbe7;
}

.__ActionDropDown .dropdown-menu ul li a {
    color: #4d4d4d;
    text-decoration: none;
    display: block;
    padding: 8px 15px;
    font-size: 12px;
}
.__ActionDropDown .dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.__ActionDropDown .btn:active {
    background-color: transparent !important;
    border-color: transparent !important;
}
.__ActionDropDownDotsBtn {
    position: absolute;
    top: 15px;
    right: 1.6%;
}
