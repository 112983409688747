/* index.scss */
/* index.scss or global.scss */

@import '../src/fonts.module.scss';
@import '/src/lib/OnBoardingRide/style/onBoarding.scss';
@import '../src/variables.module.scss';
@import '../src/components/Badge/_Badge.scss';
@import '../src/components/buttons/_Buttons.scss';
@import '../src/components/inputs/_Input';
@import '../src/components/buttons/Action-Drop-Down.scss';
@import './react-data-table.scss';
@import './components/NotificationToast/NotificationToast.scss';
@import './components/DetailsCard/Details';
@import './components/NoDataComponent/NoDataComponent.module.scss';
@import './components/Circle/_Circle.scss';
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
body {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
code {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 0px !important;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}

.NewCallCenterOrder_foodItems__qZVe- .NewCallCenterOrder_foodText__Iye2N {
  padding: 0px 0px !important;
  background: #ffffff;
}

.rdt_Table {
  // max-height: 500px;

  @media screen and (max-width: 1920px) {
    // max-height: 750px;
  }

  @media screen and (max-width: 1560px) {
    // max-height: 750px;
  }

  @media screen and (max-width: 1280px) {
    // max-height: 500px;
  }
}

.sc-dmctIk {
  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.rdt_Table {
  .rdt_TableCol,
  .rdt_TableCell {
    // min-width: 200px;
  }
}

.rdt_TableCol[data-column-id='1'],
.rdt_TableCell[data-column-id='1'] {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

body[dir='rtl'] .rdt_TableCol[data-column-id='1'],
body[dir='rtl'] .rdt_TableCell[data-column-id='1'] {
  right: 0;
  left: auto;
}

.rdt_TableCol[data-column-id='1'] {
  z-index: 2;
}

body .rdt_TableHead {
  z-index: 3;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 14px;
  border-radius: 50px;
  padding: 6px 16px;
  width: 200px;
  background-color: #f2f2f2;
  color: #004fee;
  text-align: center;
  outline: 0;
  border: none;
}
.react-datepicker-wrapper .react-datepicker__input-container input.custom-datepicker {
  width: 100%;
  border-radius: 50px;
  height: 45px;
  padding: 0px 25px;
  border-color: #e5e5e5;
  display: block;
  height: 45px;
  padding: 0px 25px;
  border-color: #e5e5e5;
  display: block;
}

.react-datepicker__month-container {
  float: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #0d6efd !important;
  color: white !important;
}

.hide-calendar {
  .react-datepicker__day-name {
    color: transparent;
    font-size: 0px;
    line-height: 0;
  }

  .react-datepicker__month {
    display: none;
  }
}

.week__container {
  cursor: pointer;
  transition: all 0.5s;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  &:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }

  &.active {
    border-radius: 0.3rem;
    background-color: #0d6efd;
    color: #fff;
  }
}

.react-datepicker {
  border: 5px solid transparent !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  padding: 15px;

  .react-datepicker__triangle::after,
  .react-datepicker__triangle::before {
    display: none;
  }
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background-color: transparent;
  border: 1px solid transparent;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepickertriangle {
  display: none;
}

.custom-date-range-picker {
  position: relative;
}

.datepicker-container {
  position: absolute;
  top: 40px; /* Adjust the top position based on your layout */
}

.css-11ww9ak {
  background-color: #216ba5 !important;
  border-radius: 0.3rem !important;
}
.css-1jn5cdg {
  background-color: #bad9f1 !important;
  border-radius: 0.3rem !important;
}

.css-vwth7n {
  gap: 3px !important;
}

.css-15m0v6v-MuiTableCell-root {
  position: static !important;
}
.css-17yby3a-MuiTableCell-root {
  position: static !important;
}
.css-1xvo577-MuiTableCell-root {
  position: static !important;
}
.css-pjebt3-MuiTableCell-root {
  position: static !important;
}
.css-i8xspc-MuiTableCell-root {
  position: static !important;
}
.css-1et1ygi-MuiTableCell-root {
  position: static !important;
}
.css-cxnshh-MuiTableCell-root {
  position: static !important;
}
.css-1r50wiu-MuiTableCell-root {
  position: static !important;
}
.css-3rz4ae-MuiTableCell-root {
  position: static !important;
}
.css-1drbrly {
  position: static !important;
}
.css-5lc03m {
  position: static !important;
}
.css-1drbrly {
  position: static !important;
}
.css-136tv8z {
  position: static !important;
}
.css-18nwzi5 {
  position: static !important;
}
.css-rsp34-MuiTableCell-root {
  position: static !important;
}
.css-z0kgyg {
  position: static !important;
}
.css-h67lzf {
  position: static !important;
}
.Dropdown_selectedOption__NIcV2 {
  z-index: 0 !important;
}
.react-datepicker__navigation-icon {
  top: 15px !important;
}

.css-1vqlznj-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
.css-1twrztz-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}

.form-label[for='FileInput'] {
  border-radius: 8px;
  border: 3px dashed #e5e5e5;
  cursor: pointer;

  svg {
    &:hover {
      transform: scale(1.1);
    }
  }
}
.form-control:not(textarea) {
  border-radius: 50px;
  padding: 10px 25px;
  border-color: #e5e5e5;

  &.attachment {
    &[type='file'] {
      display: none;
    }
  }
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  width: 30%;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: black !important;
}
.accordion-item {
  border: none !important;
}
.accordion-item:last-of-type .accordion-button.collapsed:last-child {
  // border-radius: 0px !important;
  padding-bottom: 20px;
}
.accordion-item:last-of-type .accordion-button.collapsed:focus {
  outline-color: #555;
  // background-color: #216ba5;
}

.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-body {
  padding: 10px 25px !important;
}

.accordion {
  margin-bottom: 5px;
}

.react-datepicker__input-container input::placeholder {
  color: var(--light-gray-color-6); /* Change this to the desired color */
}

.rmsc {
  .dropdown-heading {
    padding: 0px 10px !important;
  }
  .gray {
    color: #004fee !important;
  }
}

.contentBox {
  border-radius: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 25px 25px;
  margin-bottom: 30px;
  background: #fff;

  &.countCardBox {
    padding: 30px 25px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #808080;
      margin-bottom: 12px;
    }
    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #4d4d4d;
      line-height: 2rem;

      a {
        text-decoration: none;
      }
    }
  }

  .tagsList {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;

      span {
        display: block;
        border: 1px solid var(--Main2);
        padding: 5px 15px;
        border-radius: 8px;
      }
    }
  }
}

.titleReport {
  .dropdown-toggle {
    &::after {
      background-image: url(images/arrow-down-black.svg);
      border: none !important;
      width: 14px;
      height: 7px;
      vertical-align: 1px !important;
      margin-left: 10px !important;
    }
  }
}

.sc-dmctIk {
  max-height: none !important;
}

.link {
  color: #0d6efd !important;
  cursor: pointer;
}
