.NoDataComp {
  height: 48vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin-bottom: 1.2rem;
  }
  p {
    font-size: 16px;
  }
}
