.app-notification-container {
  position: absolute;
  top: 4.125rem;
  right: 1.25rem;
  width: 20rem;
  z-index: 1030;
}
@media (max-width: 991.98px) {
  .app-notification-container {
    top: 6.6875rem;
  }
}
@media (max-width: 575.98px) {
  .app-notification-container {
    width: 18.75rem;
    right: 0.625rem;
  }
}
.app-notification-container .app-notification {
  display: flex;
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  background: #f2f2f2;
  width: 100%;
  margin-bottom: 0.625rem;
  justify-content: center;
  align-items: center;
}
.app-notification-container .app-notification .notification-media {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.9375rem;
  text-align: center;
  margin: 0 10px;
}
.app-notification-container .app-notification .notification-media img {
  max-width: 2.25rem;
  max-height: 2.25rem;
  border-radius: 0.375rem;
}
.app-notification-container .app-notification .notification-media i {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.375rem;
  line-height: 2.25rem;
  text-align: center;
  overflow: hidden;
  color: #000;
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.15);
  top: 0;
}
.app-notification-container .app-notification .notification-media + .notification-info,
.dropdown-menu .dropdown-message .icon + div,
.dropdown-menu .dropdown-message .info + div,
.dropdown-menu .dropdown-setting .icon + div,
.dropdown-menu .dropdown-setting .info + div,
.dropdown-menu .dropdown-setting .option + div {
  padding-left: 0;
}
.app-notification-container .app-notification .notification-info {
  padding: 0.9375rem;
  -webkit-box-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.app-notification-container .app-notification .notification-info .notification-title {
  font-size: 0.875rem;
  margin: 0;
}
.app-notification-container .app-notification .notification-info .notification-desc {
  font-size: 0.75rem;
  margin: 0.125rem 0 0;
  color: #333;
}
.app-notification-container .app-notification .notification-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #ccc;
}
.app-notification-container .app-notification .notification-btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.625rem 0.9375rem;
  height: 50%;
  color: #333;
  font-size: 0.8125rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 1;
  text-align: center;
  width: 4.6875rem;
}
.app-notification-container .app-notification .notification-btn a:active,
.app-notification-container .app-notification .notification-btn a:focus,
.app-notification-container .app-notification .notification-btn a:hover {
  color: #007aff;
}
.app-notification-container .app-notification .notification-btn a + a {
  border-top: 1px solid #ccc;
}
.app-notification-container .app-notification .notification-btn.single-btn a {
  height: 100%;
}
.app-notification-container .app-notification.app-notification-inverse {
  background: #262626;
  color: #fff;
}
.app-notification-container .app-notification.app-notification-inverse .notification-btn a,
.app-notification-container .app-notification.app-notification-inverse .notification-info .notification-desc {
  color: #ccc;
}
.app-notification-container .app-notification.app-notification-inverse .notification-btn {
  border-left-color: #4d4d4d;
}
.app-notification-container .app-notification.app-notification-inverse .notification-btn a:active,
.app-notification-container .app-notification.app-notification-inverse .notification-btn a:focus,
.app-notification-container .app-notification.app-notification-inverse .notification-btn a:hover {
  color: #66afff;
}
.app-notification-container .app-notification.app-notification-inverse .notification-btn a + a {
  border-top: 1px solid #4d4d4d;
}
.emptyBox {
  width: 60px;
  height: 45px;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotificationModal {
  width: 19rem;
  border: 0;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  font-size: 0.8125rem;
  z-index: 9999999 !important;
  position: absolute;
  left: -290px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
}
.NotificationEmptyModal {
  width: 19rem;
  height: 24rem;
  border: 0;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  font-size: 0.8125rem;
  z-index: 9999999 !important;
  position: absolute;
  left: -290px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.NotificationModalHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  color: #000;
  padding: 0.425rem 0.9375rem;
  text-align: start;
  width: 100%;
}

.NotificationModalTitle {
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
}
.NotificationModalItem {
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.NotificationModalItem:last-child {
  border-bottom: 0;
}
.NotificationModal {
  font-family: 'Noto Kufi Arabic', sans-serif !important;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #afafaf;
    border-radius: 10px;
  }
}
.NotificationModalInfo {
  padding: 0.625rem 0.9375rem;
  white-space: initial;
  width: 266px;
}

.NotificationModalTime {
  font-size: 0.6875rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.NotificationModalDesc {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
}
.NotificationModalIcon .img {
  width: 24px;
  height: 24px;
  border-radius: 0.375rem;
  font-size: 1rem;
  text-align: center;
}
.NotificationIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
}
.NoNotification {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0px;
  color: #808080;
}
