.cardBody {
  background: transparent;
}
.contentBox {
  border-radius: 10px;
  padding: 25px 25px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  .formField {
    margin-bottom: 15px;
  }
  .formField {
    margin-bottom: 15px;

    label {
      font-style: normal;
      font-weight: 400;
      font-size: var(--font-size-small);
      line-height: 150%;
      color: var(--dark-gray-color-2);
    }
    p {
      color: var(--dark-gray-color-3);
      font-weight: bold;
      font-style: normal;
      font-size: var(--font-size-small);
      line-height: 150%;
      margin: 0px;
      border-bottom: 2px solid var(--light-gray-color-5);
      padding: 6px 0px 8px 0px;
    }
  }
}
.pageWrapperDetailsInside {
  padding: var(--padding-small);
}
.pageWrapperDetailsInsideBig {
  padding: var(--padding-big);
}
.cardWrapper {
  border: none !important;
  box-shadow: 0px 0px 2px #e5e5e5 !important;
  border-radius: 10px;
}
.card-Body {
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  border-radius: 10px;
  border: none;
}
.Box {
  padding: 25px 25px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.contentBoxAttendance {
  border-radius: 0px 0px 10px 10px;
  @extend .Box;
}

.contentBoxEmployee {
  @extend .Box;
}
.contentBoxEmployeeEnd {
  border-radius: 0px 0px 10px 10px;
  @extend .Box;
}
