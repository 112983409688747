@import '../../variables.module.scss';

:global(.tooltip) {
  :global(.tooltip-inner) {
    background-color: #fff;
    color: #808080;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 12px;
    padding: 10px 10px;
  }

  :global(.tooltip-arrow) {
    display: none;
  }

  &.themeBtn {
    background: var(--Main2);
    border-color: var(--Main2);
    color: #ffff;
  }
}

.btnSubmit {
  border-radius: 20px;
  color: red;
  border: 2px solid red;
}

.inputSize {
  input {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    min-height: 100px;
    width: 100%;
    color: #000;
    font-size: 16px;
    padding: 12px;
    font-weight: 500;

    &:focus {
      box-shadow: none;
      border-color: var(--Main2);
    }

    &:focus-visible {
      outline: none !important;
    }
  }
}

.textArea:focus {
  border-color: #a3c4bc;
  box-shadow: 0 0 0 0.1rem #8ebdb1;
}

.removeBtn {
  text-decoration: none;
  font-size: 13px;
  background-color: #ee5253;
  padding: 10px;

  color: #ffff;
  font-weight: 400;
  border-radius: 50px;
}

.themeBtn {
  background: var(--Main2);
  border: unset;
  width: 140px;
  border-radius: 100px;
  height: 50px;
  color: #ffff;
}

.dangerBtn {
  background: #eb5757;
  border: unset;
  width: 140px;
  border-radius: 100px;
  height: 50px;
  color: #ffff;
}
.customModal {
  :global(.modal-dialog) {
    display: flex;
    align-items: center;
  }
  .modal-dialog {
    max-width: 800px !important;
  }
}
.outline {
  padding: 8px 30px;
}
.emptybox {
  width: 40px;
  height: 40px;
  background-color: lightgray;
  border-radius: 2px;
}

.tooltips {
  cursor: pointer;
  position: relative;
  svg {
    color: #808080;
    width: 18px;
    height: 18px;
  }
}

.SubmitBtn {
  background: var(--Main2) !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  margin-left: 5px !important;
  padding: 8px 30px 8px 30px !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.rightLabel {
  text-decoration: none;
  font-size: 16px;
  float: right;
  color: var(--Main2);
}

.generateBtn {
  width: 111px;
  border-radius: 100px;
}

.formField {
  margin-bottom: 20px;

  :global(.form-label) {
    font-size: 17px;
    font-weight: 400;
    color: #4d4d4d;
    margin-bottom: 7px;
    &.labelHide {
      display: block;
      opacity: 0;
    }
  }

  :global(.form-control) {
    border-radius: 50px;
    height: 45px;
    padding: 0px 25px;
    border-color: #e5e5e5;

    &:focus {
      box-shadow: none;
      border-color: var(--Main2);
    }
  }

  textarea {
    &:global(.form-control) {
      border-radius: 10px;
      height: 100px;
      padding: 20px 25px;
    }
  }

  :global(.form-select) {
    border-radius: 50px;
    height: 50px;
    padding: 0px 25px;
    border-color: #e5e5e5;

    &:focus {
      box-shadow: none;
      border-color: var(--Main2);
    }
  }

  :global(.css-1s2u09g-control),
  :global(.css-1insrsq-control),
  :global(.css-1pahdxg-control) {
    border-radius: 50px;
    height: 50px;
    padding: 0px 10px 0px 25px;
    border-color: #e5e5e5;
    box-shadow: none;

    &:hover,
    &:focus {
      border-radius: 50px;
      // height: 50px;
      border-color: #e5e5e5;
      box-shadow: none;
    }

    :global(.css-1okebmr-indicatorSeparator) {
      display: none;
    }

    :global(.css-319lph-ValueContainer) {
      padding: 0px;
    }

    :global(.css-tlfecz-indicatorContainer),
    :global(.css-1gtu0rj-indicatorContainer) {
      padding: 0px 0px;
    }
  }

  .addressBtn {
    border-radius: 50px;
    height: 50px;
    padding: 0px 25px;
    border: 1px solid #e5e5e5;
    display: block;
    width: 100%;
    text-align: left;
    background: #fff;
    color: var(--Main2);
  }

  .btnSubmit {
    //  background: var(--Main2) !important;

    border-radius: 20px;
    margin-left: 5px !important;
    border: 1px solid #dadada;
    padding: 8px 40px 8px 40px !important;

    text-transform: capitalize !important;
  }
}
.formFieldSelectInputVaildationErrors {
  margin-bottom: 20px;

  :global(.form-label) {
    font-size: 17px;
    font-weight: 400;
    color: #4d4d4d;
    margin-bottom: 7px;

    &.labelHide {
      display: block;
      opacity: 0;
    }
  }

  :global(.form-control) {
    border-radius: 50px;
    height: 45px;
    padding: 0px 25px;
    border-color: #e5e5e5;

    &:focus {
      box-shadow: none;
      border-color: var(--Main2);
    }
  }

  textarea {
    &:global(.form-control) {
      border-radius: 10px;
      height: 100px;
      padding: 20px 25px;
    }
  }

  :global(.form-select) {
    border-radius: 50px;
    height: 50px;
    padding: 0px 25px;
    border-color: #e5e5e5;

    &:focus {
      box-shadow: none;
      border-color: var(--Main2);
    }
  }

  :global(.css-1s2u09g-control),
  :global(.css-1insrsq-control),
  :global(.css-1pahdxg-control) {
    border-radius: 50px;
    height: 50px;
    padding: 0px 10px 0px 25px;
    border-color: #dc3545;
    box-shadow: none;

    &:hover,
    &:focus {
      border-radius: 50px;
      // height: 50px;
      border-color: #e5e5e5;
      box-shadow: none;
    }

    :global(.css-1okebmr-indicatorSeparator) {
      display: none;
    }

    :global(.css-319lph-ValueContainer) {
      padding: 0px;
    }

    :global(.css-tlfecz-indicatorContainer),
    :global(.css-1gtu0rj-indicatorContainer) {
      padding: 0px 0px;
    }
  }

  .addressBtn {
    border-radius: 50px;
    height: 50px;
    padding: 0px 25px;
    border: 1px solid #e5e5e5;
    display: block;
    width: 100%;
    text-align: left;
    background: #fff;
    color: var(--Main2);
  }

  .btnSubmit {
    //  background: var(--Main2) !important;

    border-radius: 20px;
    margin-left: 5px !important;
    border: 1px solid #dadada;
    padding: 8px 40px 8px 40px !important;

    text-transform: capitalize !important;
  }
}

.ctdModal {
  // display: flex !important;
  // justify-content: center;
  // align-items: center;
  background-color: #0000008c;

  :global(.modal-dialog) {
    width: 100% !important;

    :global(.modal-content) {
      border: none;
      border-radius: 10px;

      :global(.modal-header) {
        padding: 20px 20px;

        :global(.modal-title) {
          font-size: 18px;
        }

        :global(.btn-close) {
          display: none;
        }
      }

      :global(.modal-body) {
        background: #fafafa;
        padding: 20px 20px;
      }

      :global(.modal-footer) {
        padding: 20px 20px;
        justify-content: space-between;

        .rightOption {
          color: #bcbcbc;
          text-decoration: none;
          font-size: 14px;
          background-color: #ee5253;

          &:hover {
            text-decoration: underline;
          }
        }

        :global(.btn) {
          min-width: 100px;
          margin: 0;
          margin-left: 15px;
          border: 1px solid #e5e5e5;
          background: #fff;
          color: #bcbcbc;
          border-radius: 50px;
          font-size: 14px;
          padding: 8px 5px;
          height: 49px;

          &.btnSubmit {
            background: var(--Main2) !important;
            border-color: var(--Main2) !important;
            color: #ffff !important;
            margin-left: 5px !important;
            padding: 8px 30px 8px 30px !important;

            text-transform: capitalize !important;
          }
        }
      }

      :global(.btnDltModal) {
        background-color: #ee5253;
        border-color: #ee5253;
        color: #fff;
        border-radius: 10px;
        min-width: 80px;
        padding: 8px 15px;
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;

        &.themeBtn {
          background: var(--Main2);
          border-color: var(--Main2);
          color: #ffff;
        }

        .icon {
          margin-right: 5px;
          margin-top: -1px;
        }

        &:focus {
          box-shadow: none;
        }

        .iconClose {
          margin-left: 10px;
          margin-top: 1px;
          cursor: pointer;
        }
      }
    }
  }
}

.ctsModal {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border: none;
      border-radius: 10px;

      :global(.modal-header) {
        padding: 20px 20px;

        :global(.modal-title) {
          font-size: 18px;
        }

        :global(.btn-close) {
          display: none;
        }
      }

      :global(.modal-body) {
        background: #fafafa;
        padding: 20px 20px;
      }

      :global(.modal-footer) {
        padding: 20px 20px;
        justify-content: space-between;

        .rightOption {
          color: #bcbcbc;
          text-decoration: none;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }
        }

        :global(.btn) {
          min-width: 100px;
          margin: 0;
          margin-left: 15px;
          border: 1px solid #e5e5e5;
          background: #fff;
          color: #bcbcbc;
          border-radius: 50px;
          font-size: 14px;
          padding: 8px 5px;

          &.btnSubmit {
            background: var(--Main2);
            border: 1px solid var(--Main2);
            color: #fff;
            text-transform: capitalize !important;
          }
        }

        :global(.btnDltModal) {
          background-color: #ee5253;
          border-color: #ee5253;
          color: #fff;
          border-radius: 10px;
          min-width: 80px;
          padding: 8px 15px;
          margin-left: 10px;
          font-weight: normal;
          font-size: 14px;
          white-space: nowrap;

          &.themeBtn {
            background: var(--Main2);
            border-color: var(--Main2);
            color: #ffff;
          }

          .icon {
            margin-right: 5px;
            margin-top: -1px;
          }

          &:focus {
            box-shadow: none;
          }

          .iconClose {
            margin-left: 10px;
            margin-top: 1px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.ctdModal {
  // display: flex !important;
  // justify-content: center;
  // align-items: center;
  background-color: #0000008c;

  :global(.modal-dialog) {
    width: 100% !important;

    :global(.modal-content) {
      border: none;
      border-radius: 10px;

      :global(.modal-header) {
        padding: 20px 20px;

        :global(.modal-title) {
          font-size: 18px;
        }

        :global(.btn-close) {
          display: none;
        }
      }

      :global(.modal-body) {
        background: #fafafa;
        padding: 20px 20px;
      }

      :global(.modal-footer) {
        padding: 20px 20px;
        justify-content: space-between;

        .rightOption {
          color: #bcbcbc;
          text-decoration: none;
          font-size: 14px;
          background-color: #ee5253;

          &:hover {
            text-decoration: underline;
          }
        }

        :global(.btn) {
          min-width: 100px;
          margin: 0;
          margin-left: 15px;
          border: 1px solid #e5e5e5;
          background: #fff;
          color: #bcbcbc;
          border-radius: 50px;
          font-size: 14px;
          padding: 8px 5px;

          &.btnSubmit {
            background: var(--Main2);
            border: 1px solid var(--Main2);
            color: #fff;
            text-transform: capitalize !important;
          }
        }

        :global(.btnDltModal) {
          background-color: #ee5253;
          border-color: #ee5253;
          color: #fff;
          border-radius: 10px;
          min-width: 80px;
          padding: 8px 15px;
          margin-left: 10px;
          font-weight: normal;
          font-size: 14px;
          white-space: nowrap;

          &.themeBtn {
            background: var(--Main2);
            border-color: var(--Main2);
            color: #ffff;
          }

          .icon {
            margin-right: 5px;
            margin-top: -1px;
          }

          &:focus {
            box-shadow: none;
          }

          .iconClose {
            margin-left: 10px;
            margin-top: 1px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.rightRed {
  color: #ff1713 !important;
}

.rightRed:hover {
  text-decoration: underline !important;
}

.sortCategory {
  cursor: move;
  border-radius: 15px;
  padding: 20px 20px;
  margin-bottom: 30px;
  background: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: var(--Color) !important;
  transition: 0.1s;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
}

.SubmitBtn {
  background: var(--Main2) !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  margin-left: 5px !important;
  padding: 8px 30px 8px 30px !important;
  text-transform: capitalize !important;
}

.ctsSubModal {
  background-color: rgba(0, 0, 0, 0.5);
}

.checkboxlabel {
  display: flex;
  align-items: center;
}

.radiojustify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxinput {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.radioinput {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.bottomRight {
  justify-content: flex-end !important;
}

.editCategory {
  display: flex;
  margin-bottom: 18px;
  align-items: center;

  ul {
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    align-items: center;

    li {
      padding-right: 20px;

      a {
        text-decoration: none;
        color: #4d4d4d;
      }
    }
  }
}

.moreOption {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 130px;
  height: 32px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  color: var(--Color);
  transition: 0.1s;

  &:hover {
    box-shadow: 1px 5px 10px #99a2ac80;
    color: var(--Main2);
  }
}

.cameraiconfleld {
  display: flex;
  align-items: center;
}

.cameraicon {
  width: 60px;
  height: 65px;
  margin-right: 10px;
  background-color: white;
  padding: 8px;
}

.editbranchfield {
  display: flex;
  align-items: center;
  background-color: #478af042;
  padding: 4px;
  padding-left: 20px;
  border-radius: 8px;
}

.fileinput {
  position: relative;
  overflow: hidden;

  h4 {
    font-weight: 400;
    font-size: 14px;
    color: #4d4d4d;
    margin-bottom: 0px;
  }

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.customerNumInput::-webkit-outer-spin-button,
.customerNumInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.businessdateCombo {
  background-color: white !important;
  color: black !important;
  width: 100% !important;
  text-align: left !important;
}

.businessdateCombo::after {
  display: none !important;
}
.pointer {
  cursor: pointer;
}
@media (min-width: 576px) {
  .ctdModal,
  .ctsModal {
    :global(.modal-dialog) {
      max-width: 600px;
      transition: all 0.2s ease !important;
    }
  }

  .ctsModalSize {
    :global(.modal-dialog) {
      max-width: 1126px;
    }
  }
}

/////delete Button

.DeleteBtn {
  background-color: #ff5b5b !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  margin-left: 5px !important;
  padding: 8px 30px 8px 30px !important;
  text-transform: capitalize !important;
  border-radius: 31px !important;
}

.SubmitBtn {
  background: var(--Main2) !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  margin-left: 5px !important;
  padding: 8px 30px 8px 30px !important;
  text-transform: capitalize !important;
}

////////////////////////////////////////
.delete {
  color: #ff5b5b;
  cursor: pointer;

  &:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
}

.colorTransparent {
  color: transparent !important;
}

.red {
  color: red;
}

/* DatePickerStyles.css */

/* Customize the outer container of the date picker */
.react-datepicker {
  font-family: 'Noto Kufi Arabic', sans-serif;
}

/* Customize the header of the date picker */
.react-datepicker__header {
  background-color: #007bff;
  color: #fff;
}

/* Customize the navigation buttons in the header */
.react-datepicker__navigation {
  color: #fff;
}

/* Customize the day names in the date picker */
.react-datepicker__day-name,
.react-datepicker__day {
  color: #333;
}

/* Customize the selected date */
.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

/* Customize the range of dates in the date picker */
.react-datepicker__day--in-range {
  background-color: #007bff;
  color: #fff;
}

/* Customize the hover effect on dates */
.react-datepicker__day--hovered {
  background-color: #007bff;
  color: #fff;
}

/* Customize the border of the input field */
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

/* Customize the input field */
.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* Customize the arrow icon next to the input field */
.react-datepicker__input-container .react-datepicker__close-icon::after,
.react-datepicker__input-container .react-datepicker__close-icon::before {
  background-color: #007bff;
}

.input {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  border-radius: 50px;
  padding: 0.6rem 0.8rem;
}

.timeButtonContainer {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: #fff;
  --tw-border-opacity: 1;
  border: 1px solid #ced4da;

  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  position: relative;
}
.timeContainer {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: #fff;
  --tw-border-opacity: 1;
  border: 1px solid #004fb5;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.timeSlotClose {
  --tw-bg-opacity: 1;
  background-color: #fff;
  border-radius: 9999px;
  border-width: 1px;
  font-size: 11px;
  font-weight: 700;
  inset-inline-end: -7px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  top: -9px;
}
.timeSlot {
  border: 0;
  width: 25px;
  &:focus-visible {
    outline: 0;
  }
}

.SubmitBtnBoard {
  background: var(--Main2) !important;
  border-color: var(--Main2) !important;
  color: #ffff !important;
  width: auto;
  height: 30px;

  font-size: 12px !important;
  text-transform: capitalize !important;
}
.descriptionAccordion {
  color: #808080;
  font-size: 14px;
  padding-bottom: 7px;
}

.progressContainer {
  background-color: #c9ddee;
  height: 16px;
  width: 100%;
  border-radius: 8px;
  margin: 10px 0px 30px 10px;
}

.progressBar {
  height: 100%;
  border-radius: 8px;
  background-color: #004fee;
}
.setupAccordion {
  margin-top: 10px;
  border: 1px solid #e1e1e1;
}
.headerAccordion {
  border-bottom: 1px solid #eeeeee;
  // margin-top: 10px;
  margin-left: 25px;
}
.titleAccordion {
  font-size: 20px;
  font-weight: 600;
  display: flex;
}
.linkAccordion {
  color: #007bff;
  cursor: pointer;
}

.btnImport {
  min-width: 100px;
  margin: 0;
  margin-left: 15px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #bcbcbc;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 8px;
  text-align: center;
}

.photoContainer {
  position: relative;
  width: 100%;
  cursor: pointer;
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 1; /* Ensure the backdrop is behind the photo and icon */
  }

  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem; /* Adjust icon size as needed */
    height: 2rem;
    fill: white; /* Adjust icon color as needed */
    z-index: 2; /* Ensure the icon is above the backdrop */
    cursor: pointer;
  }
}

.title {
  cursor: pointer;
  padding: 15px 19px 15px 19px;
  display: flex;
  border: 1px solid rgb(226, 226, 226);
}

.onboardingModal {
  :global(.modal-content) {
    width: 550px;
  }

  :global(.modal-dialog) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.modal-header) {
    font-weight: 500 !important;
    :global(.btn-close) {
      margin: 0;
    }
  }
  :global(.modal-footer) {
    padding: 20px;
    justify-content: space-between;
  }

  .ExploreBtn {
    background: #f2f2f2 !important;
    color: var(--Main2) !important;
    box-shadow: none;
    margin-left: 5px !important;
    padding: 8px 30px 8px 30px !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    display: flex;
    align-items: center;
    &:hover {
      box-shadow: none;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
  .btnClose {
    color: var(--Main2);
    font-weight: 500;
    font-size: 16px;
    padding: 8px 30px 8px 30px !important;
    border-radius: 30px !important;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .onboardingText {
    font-weight: 400;
    color: grey;
  }
}

.ctssModal {
  :global(.modal-dialog) {
    display: flex;
    align-items: center;
    justify-content: center;
    :global(.modal-content) {
      border: none;
      border-radius: 10px;
      width: 700px;
      min-height: 400px;
      :global(.modal-header) {
        padding: 20px 35px 0px 35px;
        border: none;
      }

      :global(.modal-body) {
        background: #ffffff;
        padding: 20px 35px 35px 35px;
        :global(.modal-title) {
          font-size: 25px;
          font-weight: 700;
          margin-bottom: 25px;
        }
      }
      .onBoardingModelBtn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
      }
      .downloadAppBtn {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .helpCenterLink {
        margin-left: 50px;
        text-decoration: underline;
        color: #004fee;
        font-size: 14px;
        margin-top: 70px;
      }
      :global(.modal-footer) {
        padding: 20px;
        justify-content: space-between;
      }
    }
  }
  :global(.accordion-button) {
    padding: 1rem 0rem;
  }
}
.settingsModal {
  :global(.modal-content) {
    width: 720px !important;
  }
}

@media (min-width: 576px) {
  .ctssModal {
    :global(.modal-dialog) {
      max-width: 760px;
      transition: all 0.2s ease !important;
    }
  }
}
