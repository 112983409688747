.actionTableHead {
  display: flex;
  width: 100%;
  position: static;
  top: 0;
}
.table_header {
  position: relative;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 10px;
}

.noData {
  color: #b3b3b3;
}

.ProductAddSize {
  width: 20% !important;
  display: inline-flex;
  white-space: break-spaces;
  padding: 10px 5px;
}

.noDatashow {
  visibility: hidden;
}

.headborder {
  border-bottom: 1px solid gray;
  padding: 20px 5px;
}

.actionCheckbox {
  display: flex;
  align-items: center;
  padding: 9px 0px;

  .selectedCount {
    margin-right: 15px;
    font-weight: 600;
  }

  .actionDropdown {
    :global(.dropdown) {
      :global(.btn) {
        background-color: #fff;
        border-color: #b3b3b3;
        color: #b3b3b3;
        border-radius: 50px;
        min-width: 80px;
        padding: 8px 15px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;
      }

      :global(.dropdown-menu) {
        padding: 10px 0px;
        border-radius: 10px;
        border: none;
        box-shadow: 0 1px 5px 2px #0000001f;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            :hover {
              background-color: #d6dbe7;
            }

            a {
              color: #4d4d4d;
              text-decoration: none;
              display: block;
              padding: 8px 15px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;

  &:hover input ~ .checkmark {
    background-color: #fff;
    border: 1px solid #d8d8d8;
  }

  input {
    opacity: 0;
    margin-right: 10px;
    position: absolute;

    &:checked ~ .checkmark {
      background-color: var(--Main2);
      border: 1px solid var(--Main2);
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d8d8d8;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

:global(.MuiPaper-root) {
  box-shadow: 0 0px 12px 1px #0000000f !important;
  border-radius: 8px !important;
  margin-bottom: 3x !important;

  :global(.MuiTableContainer-root) {
    width: 100%;
    overflow-x: visible;
    max-height: unset;
  }

  :global(.MuiTablePagination-root) {
    display: flex;
    justify-content: end;
    padding: 10px 10px !important;
    border: none;
  }

  :global(.MuiToolbar-root) {
    padding: 0px 0px !important;
    min-height: auto !important;
  }

  :global(.MuiTablePagination-toolbar) {
    :global(.MuiTablePagination-displayedRows) {
      margin: 0;
    }
  }

  table {
    :global(.MuiCheckbox-root) {
      color: #e5e5e5;
      border-radius: 10px;
      overflow: hidden;

      &:global(.Mui-checked) {
        color: var(--Main2);
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
      background: #fff;

      tr {
        th {
          font-size: 14px;
          font-weight: 600;
          color: #4d4d4d;
        }
      }
    }

    tbody {
      tr {
        td,
        th {
          font-size: 14px;
          color: #4d4d4d;
          cursor: pointer;
        }
      }
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  //border-top: 1px solid #e5e5e5;
  button {
    background: transparent;
    outline: none;
    border: none;
    margin: 0px;
    cursor: pointer;
    color: var(--Main2);
    transition: all 0.2s;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      color: var(--Main2);
    }

    &:disabled {
      color: #004fee;
      opacity: 50%;
      cursor: text;
    }
  }
}

.dataTableBox {
  background-color: transparent;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  .mainTitleRow {
    padding: 15px 18px;
    width: 100%;
    align-items: center;
    margin: 0;
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    gap: 10px;
    .filterList {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        :global(.btn) {
          color: #808080;
          font-size: 16px;
          padding: 4px 16px;

          border-radius: 50px;
          margin-right: 2px;
          border-color: transparent;

          &:focus {
            box-shadow: none;
          }

          &.active {
            background: var(--Main2);
            color: #fff;
          }
        }
      }
    }

    .rightActionIcons {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: end;
      align-items: center;

      li {
        margin-left: 15px;

        :global(.btn) {
          color: #808080;
          font-size: 15px;
          padding: 0px;
          border-radius: 50px;

          &:focus {
            box-shadow: none;
          }

          &.active {
            background: var(--Main2);
            color: #fff;
          }

          .refreshIcon {
            height: 35px;
          }
        }

        .refreshBtn {
          display: inline-block;

          img {
            width: 40px;
            min-width: 40px;
          }
        }

        .filterBtn {
          background-color: #f2f2f2;
          color: #004fee;
          border: none;
          border-radius: 50px;
          min-width: 80px;
          padding: 8px 20px;
          margin-left: 10px;
          font-weight: normal;
          font-size: 14px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            margin-right: 5px;
            margin-top: -1px;
          }

          .iconClose {
            margin-right: 0px;
            margin-left: 10px;
            margin-top: 1px;
            position: relative;
            z-index: 5;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
        .filterBtnSize {
          background-color: #fff;
          border-color: #b3b3b3;
          color: #b3b3b3;
          // border-radius: 50%;
          min-width: 80px;
          padding: 10px;
          margin-left: 10px;
          font-weight: normal;
          // font-size: 14px;
          white-space: nowrap;
          // height:30px;
          // width:30px;

          img {
            // margin-right: 5px;
            // margin-top: -1px;
            height: 25px;
            width: 25px;
          }
        }

        .plusDropdown {
          &:global(.dropdown) {
            :global(.dropdown-toggle) {
              background-color: var(--Main2);
              border: 1px solid transparent;
              padding: 0px;
              border-radius: 50px;
              width: 40px;
              height: 40px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #004fee;
              border: unset;
              &::after {
                display: none;
              }
            }

            :global(.dropdown-menu) {
              padding: 15px 15px;
              border-radius: 10px;
              border: none;
              box-shadow: 0 1px 5px 2px #0000001f;

              .dropDownUl {
                list-style: none;
                margin: 0;
                padding: 0;
                max-height: 300px;
                overflow-y: auto;
                color: 'Red';

                li {
                  margin: 0;

                  &:first-child {
                    label {
                      margin-top: 5px;
                    }
                  }

                  &:last-child {
                    label {
                      margin-bottom: 5px;
                    }
                  }

                  label {
                    display: block;
                    margin: 10px 0px;
                    font-size: 12px;

                    input[type='checkbox' i]:disabled {
                      cursor: no-drop;
                    }

                    &.disabled {
                      color: #e5e5e5;

                      .checkmark {
                        background-color: #e5e5e5;
                        border: 1px solid #e5e5e5;

                        // &:after {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tableCt {
  margin: 0;

  thead {
    tr {
      th {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #4d4d4d;
        border-bottom: 1px solid #e5e5e5;
        padding: 15px 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #808080;
        border-bottom: 1px solid #e5e5e5;
        padding: 15px 10px;
      }
    }
  }
}

.deleteIcon {
  color: #ee5253;
  font-size: 23px;
  cursor: pointer;

  // svg {}
}

.linkbutton {
  color: #12e6d8;
  font-size: 15px;
  cursor: pointer;
}

.linkbutton :hover {
  text-decoration: underline;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 1130px) {
  .dataTableBox {
    .mainTitleRow {
      // display: block;

      .filterList {
        overflow: hidden;
        overflow-x: auto;
        display: -webkit-box;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}
