@import './variables.module.scss';

.table__pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  border-top: 1px solid var(--light-gray-color-5);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  button {
    background: transparent;
    outline: none;
    border: none;
    margin: 0px;

    cursor: pointer;
    transition: all 0.2s;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color);

    &:hover {
      color: var(--primary-color);
    }

    &:disabled {
      color: var(--primary-color);
      opacity: 50%;
      cursor: text;
    }
  }
}

.rdt_Table {
  .rdt_TableCol,
  .rdt_TableCell {
    width: 150px;
  }
}

@mixin stick-col {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--white-color);
}

@mixin stick-col-right {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: var(--white-color);
}

.not-sticky {
  .rdt_TableHead {
    .rdt_TableCol[data-column-id='1'] {
      position: static;
    }
  }
  .rdt_TableRow {
    .rdt_TableCell {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    .rdt_TableCell[data-column-id='1'] {
      all: unset;
    }

    &:hover {
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      &:not(:last-of-type) {
      }
      .rdt_TableCell[data-column-id='1'] {
        background-color: transparent;
      }
    }
  }
}

.sticky {
  .rdt_TableHead {
    .rdt_TableCol:last-child {
      @include stick-col-right;
    }
  }

  .rdt_TableRow {
    transition: background-color 0.15s;

    .rdt_TableCell:last-child {
      @include stick-col-right;
    }
  }
}
.rdt_TableCol[data-column-id='1'] {
  @include stick-col;
}

.rdt_TableRow {
  transition: background-color 0.15s;

  .rdt_TableCell[data-column-id='1'] {
    @include stick-col;
    transition: background-color 0.15s;
  }

  &:hover {
    .rdt_TableCell[data-column-id='1'] {
      background-color: var(--light-gray-color-2);
    }
  }
}

body[dir='rtl'] .rdt_TableCol[data-column-id='1'],
body[dir='rtl'] .rdt_TableCell[data-column-id='1'] {
  right: 0;
  left: auto;
}

.rdt_TableCol[data-column-id='1'] {
  z-index: 2;
}

body .rdt_TableHead {
  z-index: 3;
}

.multi-header__table {
  .double__header {
    width: 100%;
    height: 100%;

    &-addition {
      background-color: var(--addition-color);
    }

    &-deduction {
      background-color: var(--deduction-color);
    }

    &-category {
      margin-bottom: 8px;
      padding-top: 6px;
      padding-bottom: 4px;
      color: #b3b3b3;
    }

    &-second {
      width: 200px;
      font-size: 14px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.6rem 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &-cell {
      display: flex;
      align-items: center;
      width: 200px;
      padding: 0.5rem 1rem;
      gap: 4px;

      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .rdt_TableCol:has(.double__header),
  .rdt_TableCell:has(.double__header) {
    padding: 0;
  }

  .rdt_TableCol:not(:last-child),
  .rdt_TableCell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-border__top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-border__right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.multi-attendance-header__table {
  .double__header {
    width: 100%;
    height: 100%;

    &-addition {
      background-color: var(--addition-color);
    }

    &-deduction {
      background-color: var(--deduction-color);
    }

    &-category {
      margin-bottom: 8px;
      padding-top: 6px;
      padding-bottom: 4px;
      color: #b3b3b3;
    }

    &-second {
      width: 200px;
      font-size: 14px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 70px;

      padding: 0.6rem 1rem;

      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &-cell {
      display: flex;
      align-items: center;
      width: 200px;
      padding: 0.5rem 1rem;
      gap: 4px;

      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .rdt_TableCol:has(.double__header),
  .rdt_TableCell:has(.double__header) {
    padding: 0;
  }

  .rdt_TableCol:not(:last-child),
  .rdt_TableCell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-border__top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-border__right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  border-bottom: 1px solid var(--light-gray-color-5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .filterList {
    display: flex;
    list-style: none;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #afafaf;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    li {
      .btn {
        color: var(--dark-gray-color-2);
        font-size: 16px;
        height: 34px;
        min-width: 60px;
        border-radius: 50px;
        margin-right: 2px;
        border-color: transparent;
        transition: all 0.4s;
        padding: 0px 10px;

        &:focus {
          box-shadow: none;
        }

        &.active {
          background: var(--primary-color);
          color: var(--white-color);
        }
      }
    }
  }

  .rightActionIcons {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: end;
    align-items: center;

    li {
      margin-left: 15px;

      .filterBtn {
        background-color: var(--white-color);
        border-color: var(--light-gray-color-6);
        color: var(--light-gray-color-6);
        border-radius: 50px;
        min-width: 80px;
        padding: 8px 20px;
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;

        .filter-icon {
          margin-right: 5px;
          margin-top: -1px;
        }

        .iconClose {
          margin-right: 0px;
          margin-left: 10px;
          margin-top: 1px;
          position: relative;
          z-index: 5;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.dropScroll {
  min-width: 180px;
}
