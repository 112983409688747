.button {
  padding: 6px 16px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 5px;
  min-width: 100px;

  &:disabled {
    cursor: not-allowed;
  }

  &__normal {
    color: var(--white-color);
    font-weight: 400;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.2) 0px 2px 2px 0px,
    //   rgba(232, 232, 232, 0.2) 0px 1px 10px 0px;

    &-primary {
      background-color: var(--primary-color);
    }

    &-delete {
      background-color: var(--red-color);
    }
    &-lightRed {
      background-color: var(--red-color-light);
    }

    &:not(:disabled):hover {
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.2) 0px 3px 3px 0px,
      //   rgba(232, 232, 232, 0.2) 0px 1px 10px 0px;
    }

    &:not(:disabled):active {
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.2) 0px 8px 10px 1px,
      //   rgba(0, 0, 0, 0.2) 0px 3px 14px 2px;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &__outline {
    background-color: #f2f2f2;
    color: #004fee;
  }

  &__lined {
    background-color: transparent;
    padding: 0;
    font-size: 14px;

    &-primary {
      color: var(--light-gray-color-6);
    }

    &-delete {
      color: var(--red-color);
    }

    &-blue {
      color: var(--primary-color);
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  .spinner-border {
    font-size: 8px;
  }
}
.actionsDetailsPageBtn {
  border-color: #b3b3b3;
  border-radius: 50px;
  min-width: 80px;
  padding: 8px 15px;
  white-space: nowrap;

  &:disabled {
    background-color: #80808038;
    opacity: 0.75;
    color: #615f5f;
  }
}
