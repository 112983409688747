.custom__badge {
  text-transform: capitalize;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 7px 12px;
  color: white;
  margin-left: 5px;
  height: max-content;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &-Light {
    background-color: var(--secondary-color-light);
    color: var(--secondary-color-dark);
  }
  &-Outline {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  &-Secondary {
    background-color: #f2f2f2ff;
    color: #686868;
  }

  &-Danger {
    background-color: var(--danger-light-color);
    color: var(--danger-color);
  }

  &-Success {
    color: var(--success-color);
    background-color: var(--success-light-color);
  }

  &-Info {
    background-color: var(--primary-light-color);
    color: var(--primary-color);
  }
  &-Primary {
    background-color: #e2ecfb;
    color: #004feeff;
  }
  &-Warning {
    background-color: var(--warning-light-color);
    color: var(--warning-color);
  }
  &-Dark {
    background-color: #8c8c8c;
    color: #fff;
  }

  &-small {
    padding: 3px 12px;
    border-radius: 50px;
    font-size: 11px;
    font-weight: 600;
  }
}
