.react-joyride__spotlight {
  border-radius: 15px !important;
}
.loadingScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
}
.react-joyride__tooltip {
  border-radius: 15px !important;
  padding: 0px !important;
}

$overlay-height: 0vh;

.react-joyride__overlay {
  height: $overlay-height !important;
}
// .__floater{
//   left: 18px !important;
//   // top: 15px !important;
//   // bottom: 15px !important;
// }