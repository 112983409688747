@import "../../variables.module.scss"; 

.branchDropdown {
    position: relative;
    text-align: center;
    margin-left: 10px;
    button {
        min-width: 160px;
        border-radius: 50px;
        height: 39.18px;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 5px;
        border:1px solid #E5E5E5;
        text-align: left;
        background: #fff;
        color: #c2bcbc;
        overflow:hidden;
        text-align: center;

        .dropdownIcon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            color: #c2bcbc;
        }
    }
    .dropdownOption {
        position: absolute;
        z-index: 11;
        background: #fff;
        width: 100%;
        border-radius: 15px;
        top: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        padding: 15px 15px;

        :global(.form-control) {
            border: 1px solid #cbc8c8;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            min-height: 1px;
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
    
            li {
                cursor: pointer;
                width: 100%;
                padding: 10px 15px;
    
                &:hover {
                    background-color: #ebebeb;
                }
            }
    
        }
    }
}

.selectedOption {
    border: 1px solid var(--Main2);
    padding: 5px 10px;
    border-radius: 8px;
    margin:4px 5px 4px 0px;
    // max-width: 100px ;
    height: 35px;
    color: #2b2b2b;
    transition: 0.5s;
    display: inline-block;
    position: relative;
    z-index: 12;
    &:hover {
       border: 1px solid #be0707;
    }
}

.selectedOptionDisabled {
    border: 1px solid #969696;
    padding: 5px 10px;
    border-radius: 8px;
    margin:4px 5px 4px 0px;
    // max-width: 100px ;
    height: 35px;
    color: #676767;
    transition: 0.5s;
    display: inline-block;
    position: relative;
    z-index: 12;
   
}
.filterDropdown {
    position: relative;

    button {
        width: 100%;
        border-radius: 50px;
        min-height: 50px;
        padding:10px 25px;
        border:1px solid #E5E5E5;
        text-align: left;
        background: #fff;

        .dropdownIcon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            color: #c2bcbc;
        }
        
    }

    .dropdownOption {
        position: absolute;
        z-index: 13;
        background: #fff;
        width: 100%;
        border-radius: 15px;
        top: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        padding: 15px 15px;

        :global(.form-control) {
            border: 1px solid #cbc8c8;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            min-height: 1px;
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
    
            li {
                cursor: pointer;
                width: 100%;
                padding: 10px 15px;
    
                &:hover {
                    background-color: #ebebeb;
                }
            }
    
        }
    }
}

.selectedOption {
    border: 1px solid var(--Main2);
    padding: 5px 10px;
    border-radius: 8px;
    margin:4px 5px 4px 0px;
    color: #2b2b2b;
    transition: 0.5s;
    display: inline-block;

    &:hover {
       border: 1px solid #be0707;
    }
}

.singleselectedOption {

}